<!--
 * @Description:
 * @Author: YangXin
 * @Date: 2024-08-19 08:43:52
 * @listEditTime: Do not edit
-->
<template>
  <div class="imgMain">
    <!-- :key="index" -->
    <div  class="bigImgStyle" v-if="showModul">
       <div  class="carousel-box" v-if="cardData.imgType == 'carousel'">
        <el-carousel indicator-position="none" :interval="cardData.carouselTime*1000">
          <el-carousel-item v-for="(item, index) in imgList" :key="index" :style="{alignItems:cardData.imgSize=='self-adapt'?'baseline':'center'}">
            <img :src="item.url" alt="" :style="{width:cardData.imgSize=='self-adapt'?'100%':cardData.imgwidth+'px',height:cardData.imgSize=='self-adapt'?'96%':cardData.imgheight+'px'}">
            <div class="carousel-title">{{ item.title }}</div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="img-list" v-else>
        <div class="img-item" v-for="(item, index) in imgList">
          <div v-if="item.linkType == 'built-in'" @click="modulVisible(item)">
            <el-image :style="dynamicStyles(item)" style="border-radius: 9px 9px 0 0; position: relative"
              :src="item?.url">
            </el-image>
            <div class="title" :style="{ 'width': cardData.imgwidth }">{{ item.title }}</div>
          </div>
          <!-- 内置 -->
          <div v-else-if="item.linkType == 'web-view'" @click="webViewVisible(item)">
            <el-image :style="dynamicStyles(item)" style="border-radius: 9px 9px 0 0; position: relative"
              :src="item?.url">
            </el-image>
            <div class="title" :style="{ 'width': cardData.imgwidth }">{{ item.title }}</div>
          </div>

          <div v-else-if="item.linkType == 'JumpImg'" @click="gotoUrl(item.linkUrl)">
            <el-image style="border-radius: 9px 9px 0 0; position: relative;" :style="dynamicStyles(item)"
              :src="item?.url" fit="cover">
            </el-image>
            <div class="title" :style="{ 'width': cardData.imgwidth }">{{ item.title }}</div>
          </div>
          <div v-else>
            <el-image :style="dynamicStyles(item)" style="border-radius: 9px 9px 0 0; position: relative"
              :preview-src-list="[item?.url]" :src="item?.url">
            </el-image>
            <div class="title" :style="{ 'width': cardData.imgwidth }">{{ item.title }}</div>
          </div>

        </div>
      </div>
     
    </div>
    <!-- 二级页面 -->
    <div class="detailMain" v-if="!showModul && !webViewShow">
      <div class="detail-left">
        <div class="picture">
          <div class="title">图片展示</div>
          <el-image style="height: 155px; width: 232px; border-radius: 4px; position: relative;margin-right: 10px;"
            v-for="item in detailObj.imgList" :key="item" :src="item" :preview-src-list="detailObj.imgList">
          </el-image>
        </div>
        <div class="video">
          <div class="title">视频展示</div>
          <div class="video-item" v-for="(item, index) in detailObj.mediaList" :key="index">
            <video width="100%" height="100%" muted controls ref="videoAug">
              <source type="video/mp4" :src="item.url" />
            </video>
          </div>
        </div>
        <div class="surface">
          <div class="title">国家数字农业创新应用基地建设项目进度调度表</div>
        </div>
      </div>
      <div class="detail-right">
        <div class="title">
          <span>文字编辑框</span>
          <el-button type="primary" @click="showModul = true">返回</el-button>
        </div>
        <div v-html="detailObj.content" class="con"></div>
      </div>
    </div>

    <div class="viewBox" v-if="webViewShow" style="width: 100%;height: 100%;">
      <div class="title">
        <el-button type="primary" @click="showModul = true, webViewShow = false">返回</el-button>
      </div>
      <web-view :src="detailObj.linkUrl"></web-view>
    </div>
  </div>
</template>

<script>
import videojs from "../videos/videos.vue"
import WebView from "@/views/data/components/webView/webView"
export default {
  components: {
    videojs,
    WebView
  },
  props: {
    imgList: {
      type: Array,
      default: () => [],
    },

    cardData: {
      type: Object,
      default: () => { },
    },
  },

  data() {
    return {
      srcList: [],
      srcUrl: '',
      detailObj: {},
      showModul: true,
      webViewShow: false
    }
  },
  mounted() {

  },
  methods: {
    /**
     * @Author: yangxin
     * @Date: 2024-08-29 18:11:22
     * @test: test font
     * @msg:
     * @return {*}
     */
    clickImg: function (item) {
      this.srcUrl = item.url

    },
    modulVisible(item) {
      this.detailObj = item
      this.showModul = false

    },
    webViewVisible(item) {
      this.detailObj = item
      this.showModul = false
      this.webViewShow = true
    },
    gotoUrl(url) {

      var newWindow = window.open(url, '_blank');
      newWindow.onload = function () {
        newWindow.location = url; // 确保使用HTTPS URL
      };
    },
  },
  computed: {
    /**
     * @Author: YangXin
     * @Description: 从store获取元素
     * @Date: 2024-08-07 18:23:53
     * @return {*}
     */
    // imgList() {
    //   this.imgList.map((item)=>{
    //     this.srcList.push(item.url)
    //   })
    // },
    dynamicStyles() {
      return function (data) {
        if (this.cardData.imgType == 'pic-list') {
         
              return `width:${this.cardData.imgwidth}px;height:${this.cardData.imgheight}px;`
         
          
        } else{
            return `width:250px;height:250px;`
          }
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.viewBox {
  .title {
    display: flex;
    justify-content: flex-end;
    margin: 10px 0;
  }
}

.detailMain {
  display: flex;
  height: 100%;
  justify-content: space-between;

  .detail-left {
    width: 72%;
  }

  .detail-right {
    width: 25%;
    margin-right: 1%;

    .title {
      display: flex;
      justify-content: space-between;
      font-size: 18px;
      color: #66ffff;
      margin-bottom: 8px;
    }

    .con {
      width: 100%;
      height: 92%;
      border: 1px solid #66FFFF;
      border-radius: 8px;
      padding: 16px;
      background: #0B2549
    }
  }

  .picture {
    margin-bottom: 10px;

    .title {
      font-size: 18px;
      color: #66ffff;
      margin-bottom: 8px;
    }
  }

  .video {
    margin-bottom: 10px;

    .title {
      font-size: 18px;
      color: #66ffff;
      margin-bottom: 8px;
    }

    .video-item {
      width: 232px;
      height: 155px;
    }
  }
}

.surface {
  margin-bottom: 10px;

  .title {
    font-size: 18px;
    color: #66ffff;
    margin-bottom: 8px;
  }
}

.img-list {
  display: flex;
  flex-wrap: wrap;

  .img-item {
    // flex: 0 0 230px;
    border: 1px solid rgba(0, 224, 219, .3);
    border-radius: 9px;
    background: rgba(0, 62, 92, .3);
    margin: 10px 6px 6px 6px;
    padding-bottom: 6px;
    // width: 230px;
    //float: left;

    >div {
      width: 100%;
      height: 100%;
    }

    .title {
      font-size: 18px;
      color: #fff;
      border-radius: 0 0 9px 9px;
      line-height: 27px;
      padding: 2px 10px 0 10px;
      font-size: 16px;
      // background-color: rgba(255, 255, 255, 0.1);
      // backdrop-filter: blur(2px);
      // -webkit-backdrop-filter: blur(2px);
      // border: 1px solid rgba(255, 255, 255, 0.18);
      // box-shadow: rgba(142, 142, 142, 0.19) 0px 6px 15px 0px;
      // -webkit-box-shadow: rgba(142, 142, 142, 0.19) 0px 6px 15px 0px;

      display: -webkit-box;
      /* 设置为WebKit内核的弹性盒子模型 */
      -webkit-box-orient: vertical;
      /* 垂直排列 */
      -webkit-line-clamp: 2;
      /* 限制显示两行 */
      overflow: hidden;
      /* 隐藏超出范围的内容 */
      text-overflow: ellipsis;
      /* 使用省略号 */
      width: 230px;
    }
  }
}

.imgStyle {
  margin-bottom: 10px;
  height: 33%;
  width: 100%;
  // background-color: red;
}

.bigImgStyle {
  position: relative;
  height: 100%;
  // width:calc(100% - 20px);
  width: 100%;
}

.imgMain {
  height: 100%;
  width: 100%;
  overflow-y: scroll;
}

.imgMain::-webkit-scrollbar {
  margin-top: 3px;
  /*滚动条整体样式*/
  width: 8px;
  /*高宽分别对应横竖滚动条的尺寸*/
  height: 16px;
}

.imgMain::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 5px;
  // box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(0, 217, 255, .7);
  z-index: 1;
}
.carousel-box{
  width: 100%;
  height: 100%;
  /deep/ .el-carousel__container{
    width: 100%;
    height: 100%;
  }
  /deep/ .el-carousel{
    width: 100%;
    height: 100%;
  }
  /deep/ .el-carousel__item{
    display: flex;
    align-items: center;
    justify-content: center;
    .carousel-title{
     position: absolute;
     bottom:0;
     left:0;
     white-space: nowrap; /* 确保文本在一行内显示 */
    overflow: hidden; /* 隐藏溢出的内容 */
    text-overflow: ellipsis; /* 使用省略号表示文本溢出 */
    width: 96%;
    }
  }
  /deep/ .el-carousel__arrow{
    background-color:rgba(31, 45, 61, 0.81);
  }
}

</style>
